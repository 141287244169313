import { useRef } from "react";
import axios from "axios";

export default () => {
  const api = useRef(
    axios.create({
      baseURL: `/api`
    })
  );
  return api.current;
};
