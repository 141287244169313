import React from "react";
import cn from "classnames";
import MicRecorder from "mic-recorder-to-mp3";
import Div100vh from "react-div-100vh";

import Logo from "./Logo";
import CloseButton from "./CloseButton";
import Player from "./Player";

const recorder = new MicRecorder({
  bitRate: 128
});

const SoundRecord = ({ setSound }) => {
  const [record, setRecord] = React.useState(false);
  const [recording, setRecording] = React.useState(false);
  const [ellapsed, setEllapsed] = React.useState(0);
  const [, setError] = React.useState();
  const [file, setFile] = React.useState();

  React.useEffect(() => {
    if (!recording) {
      return;
    }
    setEllapsed(0);
    const intervalId = setInterval(() => {
      setEllapsed(e => e + 100);
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, [recording]);
  React.useEffect(() => {
    if (ellapsed < 60000) {
      return;
    }
    setRecording(false);
  }, [ellapsed]);
  const [ellapsedSeconds, ellapsedMilliseconds] = (ellapsed / 1000)
    .toFixed(1)
    .toString()
    .split(".");
  return (
    <div>
      <button
        onClick={() => setRecord(true)}
        className="flex flex-col justify-between items-start h-40 w-40 bg-orange-500 hover:bg-orange-700 text-white font-bold p-4 rounded-lg cursor-pointer"
      >
        <div className="bg-orange-300 p-2 rounded">
          <svg viewBox="0 0 20 20" className="fill-current text-white h-4 w-4">
            <path
              fillRule="evenodd"
              d="M9 18v-1.062C5.054 16.445 2 13.071 2 9h2v.002A5.997 5.997 0 0 0 10 15c3.307 0 6-2.685 6-5.998V9h2c0 4.079-3.06 7.445-7 7.938V18h3v2H6v-2h3zM6 4.007A4.006 4.006 0 0 1 10 0c2.21 0 4 1.794 4 4.007v4.986A4.006 4.006 0 0 1 10 13c-2.21 0-4-1.794-4-4.007V4.007z"
            />
          </svg>
        </div>
        <span className="text-white text-xl font-light">Gravar</span>
      </button>

      {record && (
        <Div100vh className="absolute bg-white m-0 top-0 left-0 h-screen w-screen p-0 overflow-hidden lg:max-w-sm">
          <Logo />
          {!recording && (
            <div className="absolute top-0 right-0 pt-6 pr-6">
              <CloseButton
                onClick={() => {
                  setRecord(false);
                }}
              />
            </div>
          )}
          <div className="p-4 text-center flex flex-col justify-between items-center">
            {!file && (
              <h3
                className="font-light text-gray-900"
                style={{ fontVariantNumeric: "tabular-nums" }}
              >
                <span className="font-thin" style={{ fontSize: "12rem" }}>
                  {ellapsedSeconds}
                </span>
                <span className="text-3xl text-gray-400">.</span>
                <span className="text-3xl text-gray-400">
                  {ellapsedMilliseconds}
                </span>
                <span className="text-md text-gray-200">s</span>
              </h3>
            )}
            {file && (
              <Player
                file={file}
                confirmSound={() => {
                  setSound(file);
                  setRecord(false);
                  setFile(undefined);
                }}
                cancelSound={() => {
                  setEllapsed(0);
                  setFile(undefined);
                }}
              />
            )}
            {!file && (
              <button
                className={cn(
                  "absolute mb-5 bottom-0 flex flex-row items-center justify-start p-4 rounded-full shadow focus:outline-none",
                  {
                    "bg-red-600 text-white": recording,
                    "bg-white text-gray-900": !recording
                  }
                )}
                onClick={async () => {
                  if (!recording) {
                    try {
                      await recorder.start();
                    } catch (error) {
                      setError(error);
                      alert(error);
                      return;
                    }
                  } else {
                    const [buffer, blob] = await recorder.stop().getMp3();
                    const file = new File(buffer, "Gravação.mp3", {
                      type: blob.type,
                      lastModified: Date.now()
                    });
                    setFile(file);
                  }
                  setRecording(r => !r);
                }}
                style={{ width: "80%" }}
              >
                <svg viewBox="0 0 20 20" className="h-6 w-6 fill-current">
                  <path
                    fillRule="evenodd"
                    d="M9 18v-1.062C5.054 16.445 2 13.071 2 9h2v.002A5.997 5.997 0 0 0 10 15c3.307 0 6-2.685 6-5.998V9h2c0 4.079-3.06 7.445-7 7.938V18h3v2H6v-2h3zM6 4.007A4.006 4.006 0 0 1 10 0c2.21 0 4 1.794 4 4.007v4.986A4.006 4.006 0 0 1 10 13c-2.21 0-4-1.794-4-4.007V4.007z"
                  />
                </svg>
                <div className="mx-auto">
                  <span className="text-xl text-center block -ml-6">
                    {!recording ? "Gravar" : "Gravando"}
                  </span>
                </div>
              </button>
            )}
          </div>
        </Div100vh>
      )}
    </div>
  );
};

export default SoundRecord;
