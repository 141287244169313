import React from "react";
import useAPI from "./useAPI";

const OrderCompleted = ({ createAnother, setFrameProps, setJSONUrl }) => {
  const transactionId = window.location.search.replace("?t=", "");

  const api = useAPI();
  const [order, setOrder] = React.useState();

  React.useEffect(() => {
    if (!transactionId) {
      return;
    }
    const load = async () => {
      const { data } = await api.get(
        `/pagseguro-transaction?transaction=${transactionId}`
      );
      setOrder(data);
    };
    load();
  }, [api, transactionId]);

  React.useEffect(() => {
    if (!order) {
      return;
    }
    setFrameProps(order.frameProps);
    setJSONUrl(order.jsonUrl);
  }, [order, setFrameProps, setJSONUrl]);

  if (!order) {
    return null;
  }
  return (
    <div>
      <div className="shadow p-6 bg-gray-100 mb-4">
        <h2 className="font-light text-lg">
          <strong>Pedido {order.id}</strong>
        </h2>
        <h4 className="font-light text-gray-600 text-sm mb-4">
          <strong>Status:</strong> {order.status.text}
        </h4>
        <div>
          <span className="block font-bold">Dados da Entrega:</span>
          <span className="block">{order.sendTo.name}</span>
          <span className="block">
            {order.sendTo.address.street},{order.sendTo.address.number}
          </span>
          <span className="block">{order.sendTo.address.complement}</span>
          <span className="block">
            {order.sendTo.address.city} - {order.sendTo.address.state}
          </span>
          <span className="block">
            {`${order.sendTo.address.postalCode}`.padStart(8, "0")}
          </span>
          <a
            className="font-light text-gray-600 text-sm mb-4"
            href={`mailto:luis@luisrudge.net?subject=${encodeURIComponent(
              "Pergunta sobre o pedido "
            )}${order.id}`}
          >
            Algo errado? Clique para enviar um e-mail
          </a>
        </div>
      </div>
      <button
        onClick={createAnother}
        className="block uppercase mx-auto shadow bg-green-800 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white py-3 px-10 rounded mb-1"
      >
        Criar outro quadro
      </button>
    </div>
  );
};

export default OrderCompleted;
