import React from "react";
import Player from "./Player";
import Logo from "./Logo";

const SoundUpload = ({ setSound, setFrameText }) => {
  const fileInput = React.useRef(null);
  const [file, setFile] = React.useState();
  return (
    <div>
      <label
        htmlFor="sound-upload"
        className="flex flex-col justify-between items-start h-40 w-40 bg-indigo-500 hover:bg-indigo-700 text-white font-bold p-4 rounded-lg cursor-pointer"
      >
        <div className="bg-indigo-300 p-2 rounded">
          <svg viewBox="0 0 20 20" className="fill-current text-white h-4 w-4">
            <path
              fillRule="evenodd"
              d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z"
            />
          </svg>
        </div>
        <span className="text-white text-xl font-light">Upload</span>
        <input
          ref={fileInput}
          id="sound-upload"
          type="file"
          className="hidden"
          onChange={e => setFile(e.target.files[0])}
        />
      </label>

      {file && (
        <div className="absolute bg-white m-0 top-0 left-0 h-screen w-screen p-0 overflow-hidden">
          <Logo />
          <div className="p-4">
            <Player
              file={file}
              confirmSound={() => {
                setSound(file);
                setFrameText(
                  file.name
                    .split(".")
                    .slice(0, -1)
                    .join(".")
                );
                setFile(undefined);
              }}
              cancelSound={() => {
                setFile(undefined);
                fileInput.current.value = "";
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SoundUpload;
