import React from "react";
import Div100vh from "react-div-100vh";

import Player from "./Player";
import Input from "./Input";
import useAPI from "./useAPI";
import Logo from "./Logo";
import Loading from "./Loading";
import CloseButton from "./CloseButton";

const SoundYoutube = ({ setJSONUrl, setFrameText, setProcessing }) => {
  const api = useAPI();
  const [searching, setSearching] = React.useState(false);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState();
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [videoPreview, setVideoPreview] = React.useState();
  const [video, setVideo] = React.useState();

  React.useEffect(() => {
    if (!search) {
      return;
    }
    const load = async () => {
      setLoading(true);
      const { data } = await api.get(
        `/search-youtube?q=${encodeURIComponent(search)}`
      );
      setResults(data);
      setLoading(false);
    };
    load();
  }, [api, search]);
  React.useEffect(() => {
    if (!video) {
      return;
    }
    const load = async () => {
      setProcessing(true);
      setSearching(false);
      const { data } = await api.get(
        `/youtube?videoId=${encodeURIComponent(video.id)}`
      );
      setJSONUrl(data.url);
      setFrameText(video.title);
      setProcessing(false);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, video]);
  return (
    <div className="w-full">
      <button
        onClick={() => setSearching(!searching)}
        className="flex flex-row items-start h-20 w-full bg-red-500 hover:bg-red-700 text-white font-bold p-4 rounded-lg cursor-pointer"
      >
        <div className="bg-red-300 p-2 rounded mr-4">
          <svg viewBox="0 0 24 24" className="fill-current text-white h-4 w-4">
            <path
              fillRule="evenodd"
              d="M9.522 15.553V8.81l6.484 3.383-6.484 3.36zM23.76 7.641s-.235-1.654-.954-2.382c-.913-.956-1.936-.96-2.405-1.016C17.043 4 12.005 4 12.005 4h-.01s-5.038 0-8.396.243c-.47.055-1.492.06-2.406 1.016C.474 5.987.24 7.641.24 7.641S0 9.584 0 11.525v1.822c0 1.942.24 3.884.24 3.884s.234 1.653.953 2.382c.914.956 2.113.926 2.647 1.026 1.92.184 8.16.241 8.16.241s5.043-.007 8.401-.25c.47-.056 1.492-.061 2.405-1.017.72-.729.954-2.382.954-2.382s.24-1.942.24-3.885v-1.82c0-1.942-.24-3.885-.24-3.885z"
            />
          </svg>
        </div>
        <span className="text-white text-xl font-light">
          {!searching ? "Escolher do YouTube" : "Fechar"}
        </span>
      </button>
      {searching && (
        <Div100vh className="absolute bg-white m-0 top-0 left-0 h-screen w-screen p-0 overflow-hidden lg:max-w-sm">
          <Logo />
          <div className="absolute top-0 right-0 pt-6 pr-6">
            <CloseButton
              onClick={() => {
                setSearching(false);
              }}
            />
          </div>
          <div className="p-4 overflow-hidden h-full flex flex-col">
            <form
              className="flex-none"
              onSubmit={e => {
                e.preventDefault();
                setVideoPreview(undefined);
                setSearch(text);
              }}
            >
              <h2 className="text-2xl text-center font-light leading-tight text-gray-900 self-center mb-6">
                Pesquisar no YouTube
              </h2>
              <Input
                autoFocus
                value={text}
                onChange={e => setText(e.target.value)}
                disabled={loading}
                button={
                  <button
                    type="submit"
                    className="h-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 shadow rounded rounded-l-none"
                    disabled={loading}
                  >
                    <span role="img" title="Search" aria-label="Search icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="fill-current text-white h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.906 14.32a8 8 0 1 1 1.414-1.414l5.337 5.337-1.414 1.414-5.337-5.337zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                        />
                      </svg>
                    </span>
                  </button>
                }
              />
            </form>
            {loading && (
              <div className="flex-1 flex flex-row items-center justify-center w-full py-4 h-full">
                <Loading />
              </div>
            )}
            {!loading && !videoPreview && results.length > 0 && (
              <div className="flex-1 flex flex-col w-full py-4 max-h-full overflow-x-scroll scrolling-touch">
                {results.map(v => (
                  <div
                    key={v.id}
                    className="flex flex-wrap bg-white border-b border-blue-tial-100"
                    onClick={() => setVideoPreview(v)}
                  >
                    <div className="flex w-full m-4">
                      <div className="flex items-center">
                        <img
                          alt="thumbnail"
                          className="h-auto w-24"
                          src={v.thumbnails.medium.url}
                        />
                        <div className="flex flex-col p-4">
                          <h3 className="font-bold text-md text-tial-400">
                            {v.title}
                          </h3>
                          <span className="font-light text-sm">
                            {v.channelName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!loading && videoPreview && (
              <Player
                youtubeVideoId={videoPreview.id}
                cancelSound={() => setVideoPreview(undefined)}
                confirmSound={() => setVideo(videoPreview)}
              />
            )}
          </div>
        </Div100vh>
      )}
    </div>
  );
};

export default SoundYoutube;
