import React from "react";
import * as Azure from "@azure/storage-blob";
import createId from "uuid4";
import Div100vh from "react-div-100vh";

import useAPI from "./useAPI";
import SoundUpload from "./Sound.Upload";
import SoundRecord from "./Sound.Record";
import SoundYoutube from "./Sound.Youtube";
import Logo from "./Logo";
import Loading from "./Loading";

const azureUpload = async (file, id, type, onProgress) => {
  const blobName = `${id}.${type}`;
  const blobURL = Azure.BlobURL.fromContainerURL(
    Azure.ContainerURL.fromServiceURL(
      new Azure.ServiceURL(
        "https://somnoquadro.blob.core.windows.net/?sv=2018-03-28&ss=b&srt=sco&sp=rwdlac&se=2019-12-31T07:08:27Z&st=2019-04-29T00:08:27Z&spr=https&sig=fqA%2BBj8WT3y6IzWv9HjpFzZZkIpQb5Sq4wUo8cgF7qY%3D",
        Azure.StorageURL.newPipeline(new Azure.AnonymousCredential())
      ),
      "sounds"
    ),
    blobName
  );
  const blockBlobURL = Azure.BlockBlobURL.fromBlobURL(blobURL);

  await blockBlobURL.upload(Azure.Aborter.none, file, file.size, {
    metadata: {
      originalName: encodeURIComponent(file.name),
      type: file.type
    },
    progress: p => onProgress(Math.round((p.loadedBytes * 100) / file.size))
  });
  return blockBlobURL.url;
};

const Sound = ({ setJSONUrl, setFrameText }) => {
  const api = useAPI();
  const [sound, setSound] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  React.useEffect(() => {
    if (!sound) {
      return;
    }

    const upload = async () => {
      const id = createId();
      const type = sound.type.replace("audio/", "");
      setUploadProgress(0);
      setUploading(true);
      const soundUrl = await azureUpload(sound, id, type, p =>
        setUploadProgress(p)
      );
      setUploadProgress(100);
      setUploading(false);
      setProcessing(true);
      const { data } = await api.get(
        `/soundtojson?url=${encodeURIComponent(soundUrl)}&type=${type}`
      );
      setProcessing(false);
      setJSONUrl(data.url);
    };
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound, api]);
  return (
    <>
      {(uploading || processing) && (
        <Div100vh className="absolute bg-white m-0 top-0 left-0 h-screen w-screen p-0 overflow-hidden">
          <Logo />
          <div className="absolute top-0 w-full p-4 overflow-hidden h-full flex flex-col">
            <div className="flex-1 flex flex-col items-center justify-center w-full py-4 h-full">
              {uploading && (
                <h3
                  className="font-light text-gray-800"
                  style={{ fontVariantNumeric: "tabular-nums" }}
                >
                  <span className="font-thin" style={{ fontSize: "12rem" }}>
                    {uploadProgress}
                  </span>
                  <span className="text-xl text-gray-500">%</span>
                </h3>
              )}
              {processing && (
                <span className="text-xl text-gray-500">
                  Processando seu arquivo. <br />
                  Isso leva alguns segundos.
                </span>
              )}
              <Loading />
            </div>
          </div>
        </Div100vh>
      )}
      <div className="p-6 flex-1 flex flex-col items-center justify-center">
        <h2 className="text-2xl text-center font-light leading-tight text-gray-900 self-center mb-16">
          Escolha um <strong className="text-pink-700">som</strong> para começar
        </h2>
        <div>
          <div className="w-full flex flex-row justify-between items-center">
            <div className="mr-2">
              <SoundUpload setSound={setSound} setFrameText={setFrameText} />
            </div>
            <div className="ml-2">
              <SoundRecord setSound={setSound} setFrameText={setFrameText} />
            </div>
          </div>
          <div className="w-full mt-4">
            <SoundYoutube
              setJSONUrl={setJSONUrl}
              setFrameText={setFrameText}
              setProcessing={setProcessing}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sound;
