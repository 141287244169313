import * as d3select from "d3-selection";
import * as d3scale from "d3-scale";
import * as d3Array from "d3-array";

// export type RenderProps = {
//   barWidth: number;
//   bars: number;
//   waveformHeight: number;
//   useTwoPoints: boolean;
//   backgroundColor: string;
//   colors: string[];
//   text: string;
//   textSize: number;
//   textColor: string;
//   textQuoteAttribution: string;
//   textQuoteAttributionColor: string;
//   textQuoteAttributionSize: number;
//   textQuoteAttributionDistance: number;
//   textQuoteMarksColor: string;
// };

export const renderFrame = ($container, svgCss, data, props) => {
  console.log("render");
  const width = 6000;
  const height = 4800;
  d3select.select(".snq-svg").remove();
  const svg = d3select
    .select($container)
    .append("svg")
    .classed(`${svgCss} snq-svg`, true)
    .attr("xmlns", "http://www.w3.org/2000/svg")
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMidYMin meet");

  svg
    .append("rect")
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("fill", props.backgroundColor);
  if (true) {
    svg
      .append("polyline")
      .attr("points", `0,0 ${width},0 ${width},${height} 0,${height} 0,0`)
      .attr("fill", "none")
      .attr("stroke", "#111111")
      .attr("stroke-width", "300");
  }
  svg
    .append("defs")
    .append("style")
    .attr("type", "text/css")
    .text(
      `@import url('https://fonts.googleapis.com/css?family=Open+Sans:300');`
    );

  const internalData = bucketize(data, props.bars, props.useTwoPoints);

  const minMax = [
    props.useTwoPoints ? d3Array.min(internalData, d => d) : 0,
    d3Array.max(internalData, d => d)
  ];

  const waveformHeight = height * 0.6;
  const y = d3scale
    .scaleLinear()
    .domain(minMax)
    .range([props.useTwoPoints ? -waveformHeight : 0, waveformHeight]);

  var colors = d3scale
    .scaleLinear()
    .domain(d3Array.ticks(0, minMax[1], props.colors.length))
    .range(props.colors);

  const widthWithPadding = width * 0.9;
  const waveXposition = (width - widthWithPadding) / 2;
  svg
    .append("svg")
    .attr("width", widthWithPadding)
    //.attr("transform", `translate(${widthWithPadding / 2})`)
    .attr("x", waveXposition)
    .selectAll("rect")
    .data(internalData)
    .enter()
    .append("rect")
    .attr("rx", 0)
    .attr("ry", 0)
    .attr("x", (d, i) => i * (widthWithPadding / internalData.length))
    .attr(
      "y",
      d =>
        height -
        Math.abs(y(d * (props.waveformHeight / 100)) / 2) -
        height / 2 +
        2
    )
    .attr(
      "width",
      (widthWithPadding / internalData.length) * (props.barWidth / 10)
    )
    .attr("height", d => Math.abs(y(d * (props.waveformHeight / 100))))
    .attr("fill", d => colors(Math.abs(d)));

  const svgTextGroup = svg.append("g");
  svgTextGroup.attr("transform", `translate(${width / 2}, ${height * 0.88})`);

  const svgText = svgTextGroup.append("text");

  svgText
    .attr("font-family", "Open Sans")
    .attr("font-weight", 300)
    .attr("text-anchor", "middle")
    .attr("font-size", props.textSize);

  svgText
    /*.append('svg:tspan')
    .style('fill', props.textQuoteMarksColor)
    .text('“')*/
    .append("svg:tspan")
    .style("fill", props.textColor)
    .text(props.text.toUpperCase());
  /*.append('svg:tspan')
    .style('fill', props.textQuoteMarksColor)
    .text('„')*/

  // const svgQuoteAttribution = svgTextGroup.append("text");
  // svgQuoteAttribution
  //   .attr("y", props.textQuoteAttributionDistance)
  //   .attr("font-family", "Open Sans")
  //   .attr("font-weight", 300)
  //   .attr("font-size", props.textQuoteAttributionSize)
  //   .attr("text-anchor", "middle")
  //   //.text(`⸻ ${props.textQuoteAttribution} ⸻`.toUpperCase())
  //   .text(`${props.textQuoteAttribution}`.toUpperCase())
  //   .style("fill", props.textQuoteAttributionColor);
};

const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

const bucketize = (data, numberOfBars, useTwoPoints) => {
  const buckets = [];
  let chunkSize;
  if (useTwoPoints) {
    chunkSize = Math.floor(data.length / (numberOfBars / 2));
  } else {
    chunkSize = Math.floor(data.length / numberOfBars);
  }

  const chunks = chunkArray(data, chunkSize);
  chunks.forEach(c => {
    if (c.length < chunkSize) {
      //this is the last chunk, so keep it simple
      const minV = Math.min(...c);
      const maxV = Math.max(...c);
      if (useTwoPoints) {
        buckets.push(minV);
      }
      buckets.push(maxV);
    } else {
      c.sort((a, b) => a - b);
      if (useTwoPoints) {
        buckets.push(c[Math.floor(c.length * 0.2)]);
      }
      buckets.push(c[Math.floor(c.length * 0.8)]);
    }
  });
  return buckets;
};
