import React from "react";
import { Range, getTrackBackground } from "react-range";

const CustomRange = ({ label, labelColor, noSteps, ...rangeProps }) => {
  return (
    <div className="mb-6">
      <span className="font-light mb-6 block" style={{ color: labelColor }}>
        {label}
      </span>
      <div className="px-3">
        <Range
          {...rangeProps}
          //step={noSteps ? undefined : (rangeProps.max + rangeProps.min) / 10}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="bg-pink-600 h-2 rounded-lg"
              style={{
                background: getTrackBackground({
                  values: rangeProps.values,
                  colors: ["#d53f8c", "#eaedf4"],
                  min: rangeProps.min,
                  max: rangeProps.max
                })
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="bg-pink-600 h-8 w-8 rounded-full border-white border-2"
            />
          )}
          render
        />
      </div>
    </div>
  );
};

export default CustomRange;
