import React from "react";

import logo from "./images/logo-r.jpeg";

const Logo = () => {
  return (
    <div className="flex-none bg-white p-1 flex justify-around items-center">
      <img className="font-medium h-10" src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
