import React from "react";
import useAPI from "./useAPI";

const Pay = ({ frameProps, jsonUrl, reset }) => {
  const api = useAPI();
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState();
  return (
    <div className="">
      <div className="shadow p-6 bg-gray-100 mb-4">
        <h2 className="font-light text-lg">
          <strong>1.</strong> Quadro customizado
        </h2>
        <h4 className="font-light text-gray-600 text-sm mb-4 ml-6">
          50cm x 40cm
        </h4>
        <h2 className="font-light text-3xl self-center">
          <strong>R$ 129,00</strong>
          <p className="text-sm">+ frete (calculado no próximo passo)</p>
        </h2>
      </div>
      {!link && (
        <div className="flex flex-row items-start">
          <button
            className="block mx-auto focus:outline-none text-sm p-3"
            onClick={reset}
            style={{ color: frameProps.textColor }}
          >
            Começar de novo
          </button>
          <div>
            <button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const { data } = await api.post("/pay", {
                  frameProps,
                  jsonUrl
                });
                setLink(data.checkoutRedirectUrl);
                setLoading(false);
              }}
              className="block uppercase mx-auto shadow bg-green-800 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white py-3 px-10 rounded mb-1"
            >
              {!loading ? "Comprar" : "Criando pedido"}
            </button>
            <p
              className="text-sm text-center"
              style={{ color: frameProps.textColor }}
            >
              Pagamento via
              <span className="text-green-500"> pagseguro</span>
            </p>
          </div>
        </div>
      )}

      {link && (
        <div>
          <a href={link} className="shadow">
            <img
              className="block mx-auto"
              src="https://stc.pagseguro.uol.com.br/public/img/botoes/pagamentos/120x53-pagar.gif"
              alt="Pagar com PagSeguro"
            />
            <p className="mt-2 text-gray-600 text-xs text-center">
              Clique para pagar com
              <span className="text-green-500"> pagseguro</span>
            </p>
          </a>
        </div>
      )}
    </div>
  );
};

export default Pay;
