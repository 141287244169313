import React from "react";
import pb from "pretty-bytes";

const createFileUrl = file =>
  (window.URL || window.webkitURL).createObjectURL(file);

const Player = ({ file, youtubeVideoId, cancelSound, confirmSound }) => {
  return (
    <div className="bg-white p-4">
      {file && (
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{file.name}</h2>

          <p className="text-sm font-normal text-gray-600">{pb(file.size)}</p>
          <audio className="my-6 w-full" controls controlsList="nodownload">
            <source src={createFileUrl(file)} type={file.type} />
          </audio>
        </div>
      )}
      {youtubeVideoId && (
        <div className="aspect-ratio-16/9 relative block h-0 p-0 overflow-hidden">
          <iframe
            className="absolute top-0 left-0 h-full w-full"
            title="youtube-preview"
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
      <div className="flex items-center justify-between p-2 w-full">
        <button
          className="bg-red-500 hover:bg-red-700 text-white p-4 rounded"
          onClick={cancelSound}
        >
          Usar outro som
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white p-4 rounded"
          onClick={confirmSound}
        >
          Usar esse som
        </button>
      </div>
    </div>
  );
};

export default Player;
