import React from "react";

import CustomRange from "./CustomRange";
import Input from "./Input";

const Text = ({
  text,
  textColor,
  onTextChanged,
  textSize,
  onTextSizeChanged
}) => {
  return (
    <div className="flex flex-col justify-start">
      <Input
        label="Texto"
        labelColor={textColor}
        placeholder="Seu texto aqui"
        value={text}
        onChange={e => onTextChanged(e.target.value)}
      />
      {text && (
        <CustomRange
          label="Tamanho"
          labelColor={textColor}
          min={30}
          max={300}
          values={[textSize]}
          onChange={values => onTextSizeChanged(values[0])}
        />
      )}
    </div>
  );
};

export default Text;
