import React from "react";
import cn from "classnames";

const Input = ({ label, labelColor, button, ...props }) => {
  return (
    <div className="mb-4 w-full">
      <span className="font-light mb-4 block" style={{ color: labelColor }}>
        {label}
      </span>
      <div className="px-3 flex flex-row items-center">
        <input
          type="text"
          className={cn(
            "h-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-300",
            {
              "rounded-r-none focus:border-r-0": !!button
            }
          )}
          {...props}
        />
        {button}
      </div>
    </div>
  );
};
export default Input;
