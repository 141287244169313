import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import Div100vh from "react-div-100vh";

import cn from "classnames";
import Sound from "./Sound";
import Colors from "./Colors";
import Shapes from "./Shapes";
import Text from "./Text";
import Pay from "./Pay";

import useAPI from "./useAPI";
import { renderFrame } from "./utils";

import OrderCompleted from "./OrderCompleted";
import Logo from "./Logo";

const nav = [
  {
    step: "colors",
    text: "Cores"
  },
  {
    step: "shapes",
    text: "Formas"
  },
  {
    step: "text",
    text: "Texto"
  },
  {
    step: "pay",
    text: "Finalizar"
  }
];

const reset = () => {
  window.location.assign("/");
};

const renderFrameDebounced = debounce(
  (a, b, c, d) => renderFrame(a, b, c, d),
  70
);

const App = () => {
  const isPagseguroUpdate = window.location.pathname === "/p";
  const api = useAPI();
  const [isLargeWindow, setIsLargeWindow] = useState(window.innerWidth > 1024);
  const [open, setOpen] = useState(true);
  const [step, setStep] = useState();
  const [jsonUrl, setJSONUrl] = useState();
  const [jsonData, setJSONData] = useState([]);
  const [frameProps, setFrameProps] = useState({
    barWidth: 4,
    bars: 100,
    waveformHeight: 75,
    useTwoPoints: true,
    backgroundColor: "#FFFFFF",
    colors: ["#30A9DE", "#EFDC05", "#E53A40", "#090707"].reverse(),
    text: "- Som No Quadro -",
    textSize: 150,
    textColor: "#333333",
    textQuoteAttribution: "- Som No Quadro -",
    textQuoteAttributionSize: 100,
    textQuoteAttributionDistance: 180,
    textQuoteAttributionColor: "#404040",
    textQuoteMarksColor: "#999999"
  });
  const frameRef = useRef(null);
  const frameRefLg = useRef(null);
  useEffect(() => {
    setStep(isPagseguroUpdate ? "order" : "sound");
  }, [isPagseguroUpdate]);

  useEffect(() => {
    if (!jsonUrl) {
      return;
    }
    const load = async () => {
      const { data } = await api.request({
        baseURL: "",
        url: jsonUrl
      });
      setJSONData(data);
    };
    load();
  }, [jsonUrl, api]);

  useEffect(() => {
    if (jsonData.length === 0) {
      return;
    }
    renderFrameDebounced(
      isLargeWindow ? frameRefLg.current : frameRef.current,
      "max-h-full h-auto w-full",
      jsonData,
      frameProps
    );
  }, [isLargeWindow, jsonData, frameProps]);
  useEffect(() => {
    const handleResize = () => setIsLargeWindow(window.innerWidth >= 1024);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  // useEffect(() => {
  //   setJSONUrl(
  //     "https://somnoquadro.blob.core.windows.net/jsons/yt-E5PQGrRufe8.json"
  //   );
  //   setStep("colors");
  // }, []);
  const scrollHeightSize = isLargeWindow ? "95%" : "85%";
  return (
    <div className="lg:flex lg:flex-row">
      <Div100vh className="flex flex-col overflow-hidden flex-grow lg:max-w-sm lg:shadow-lg">
        <Logo />
        {step === "sound" && (
          <Sound
            setJSONUrl={u => {
              setJSONUrl(u);
              setStep("colors");
            }}
            setFrameText={text => {
              setFrameProps({
                ...frameProps,
                text
              });
            }}
          />
        )}
        {step !== "sound" && (
          <>
            <div
              className={cn({
                "p-2 flex flex-row items-center justify-center lg:hidden": true,
                "flex-grow h-10 w-auto": open && !isPagseguroUpdate,
                "flex-1": !open || isPagseguroUpdate
              })}
              ref={frameRef}
              style={{
                backgroundColor:
                  !open || isPagseguroUpdate ? frameProps.backgroundColor : ""
              }}
            />
            {!isPagseguroUpdate && (
              <div
                className={cn({
                  "bg-white shadow-md rounded rounded-t-xlg": true,
                  "flex-grow h-48": open,
                  "flex-none": !open
                })}
              >
                <>
                  <div
                    className="flex justify-center cursor-pointer bg-gray-100 lg:hidden"
                    onClick={() => setOpen(!open)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 m-2"
                      viewBox="0 0 320 512"
                      style={{ transform: open ? "" : "rotate(180deg)" }}
                    >
                      <path
                        d="M143 256L7.05 120.37a23.78 23.78 0 0 1 0-33.8L29.64 64a23.94 23.94 0 0 1 33.89 0l96.37 96.13L256.27 64a23.94 23.94 0 0 1 33.89 0L313 86.47a23.78 23.78 0 0 1 0 33.8L177 255.88a23.94 23.94 0 0 1-34 .1z"
                        className="fill-current text-gray-600"
                      />
                      <path
                        d="M143 447.89L7.05 312.34a23.77 23.77 0 0 1 0-33.79L29.74 256a23.94 23.94 0 0 1 33.89 0L160 352.11l96.47-96a23.94 23.94 0 0 1 33.89 0L313 278.65a23.77 23.77 0 0 1 0 33.79L177 448a24 24 0 0 1-34-.11z"
                        className="fill-current text-gray-900"
                      />
                    </svg>
                  </div>
                  {open && (
                    <>
                      <nav className="bg-gray-100 flex flex-row items-center justify-around">
                        {nav.map(n => (
                          <button
                            key={n.step}
                            onClick={() => setStep(n.step)}
                            className={cn("font-light p-4 focus:outline-none", {
                              "text-pink-600 border-b-2 border-pink-600":
                                n.step === step,
                              "text-blue-800": n.step !== step
                            })}
                          >
                            {n.text}
                          </button>
                        ))}
                      </nav>
                      <div
                        className="p-6 overflow-x-scroll scrolling-touch"
                        style={{
                          height: scrollHeightSize,
                          maxHeight: scrollHeightSize,
                          backgroundColor: frameProps.backgroundColor
                        }}
                      >
                        {step === "colors" && (
                          <Colors
                            backgroundColor={frameProps.backgroundColor}
                            onBackgroundColorChanged={backgroundColor =>
                              setFrameProps(f => ({
                                ...f,
                                backgroundColor
                              }))
                            }
                            textColor={frameProps.textColor}
                            onTextColorChanged={textColor =>
                              setFrameProps(f => ({
                                ...f,
                                textColor
                              }))
                            }
                            pallete={frameProps.colors}
                            setPallete={p =>
                              setFrameProps({
                                ...frameProps,
                                colors: p
                              })
                            }
                          />
                        )}
                        {step === "shapes" && (
                          <Shapes
                            bars={frameProps.bars}
                            textColor={frameProps.textColor}
                            onBarsChanged={bars =>
                              setFrameProps({ ...frameProps, bars })
                            }
                            barWidth={frameProps.barWidth}
                            onBarWidthChanged={barWidth =>
                              setFrameProps({ ...frameProps, barWidth })
                            }
                            waveformHeight={frameProps.waveformHeight}
                            onWaveformHeightChanged={waveformHeight =>
                              setFrameProps({
                                ...frameProps,
                                waveformHeight
                              })
                            }
                          />
                        )}
                        {step === "text" && (
                          <Text
                            text={frameProps.text}
                            textColor={frameProps.textColor}
                            onTextChanged={text =>
                              setFrameProps({
                                ...frameProps,
                                text
                              })
                            }
                            textSize={frameProps.textSize}
                            onTextSizeChanged={textSize =>
                              setFrameProps({
                                ...frameProps,
                                textSize
                              })
                            }
                          />
                        )}
                        {step === "pay" && (
                          <Pay
                            frameProps={frameProps}
                            jsonUrl={jsonUrl}
                            reset={reset}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              </div>
            )}
            {isPagseguroUpdate && (
              <div
                className="flex-1 p-6 overflow-x-scroll scrolling-touch"
                style={{
                  height: scrollHeightSize,
                  maxHeight: scrollHeightSize
                }}
              >
                <OrderCompleted
                  createAnother={reset}
                  setJSONUrl={setJSONUrl}
                  setFrameProps={setFrameProps}
                />
              </div>
            )}
          </>
        )}
      </Div100vh>
      <div className="hidden lg:flex bg-gray-300 flex-1 p-4 flex-row items-center justify-center">
        <div className="flex-grow max-w-4xl" ref={frameRefLg} />
      </div>
    </div>
  );
};

export default App;
