import React from "react";
import cn from "classnames";

const ColorPicker = ({
  label,
  labelColor,
  colors,
  color,
  justify = "start",
  onColorChanged
}) => (
  <div>
    <label
      className="font-light text-pink-500 mb-4 block"
      style={{ color: labelColor }}
    >
      {label}
    </label>
    <div className={cn("flex flex-row items-center", `justify-${justify}`)}>
      {colors.map(c => (
        <div
          key={c}
          className={cn(
            "h-10 w-10 rounded-full shadow m-2 cursor-pointer focus:outline-none",
            {
              "border-2 border-pink-600": color === c
            }
          )}
          style={{ backgroundColor: c }}
          onClick={() => onColorChanged(c)}
        />
      ))}
    </div>
  </div>
);

export default ColorPicker;
