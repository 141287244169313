import React from "react";
import cn from "classnames";
import ColorPicker from "./ColorPicker";
const bgColors = ["#FFFFFF", "#333333"];
const palletes = [
  ["#30A9DE", "#EFDC05", "#E53A40", "#090707"].reverse(),
  ["#9DC8C8", "#58C9B9", "#519D9E", "#D1B6E1"],
  ["#FFEEE4", "#F17F42", "#CE6D39", "#000000"],
  ["#A593E0", "#E0E3DA", "#FFFFF3", "#566270"],
  ["#F6B352", "#F68657", "#383A3F", "#1F2124"],
  ["#D7FFF1", "#8CD790", "#77AF9C", "#285943"],
  ["#D499B9", "#9055A2", "#2E294E", "#011638"],
  ["#FBFFB9", "#FDD692", "#EC7357", "#754F44"],
  ["#C5E99B", "#8FBC94", "#548687", "#56445D"],
  ["#E71D36", "#2EC4B6", "#EFFFE9", "#011627"],
  ["#DE6449", "#791E94", "#FFFFF2", "#41D3BD"],
  ["#5CAB7D", "#5A9367", "#44633F", "#3F4B3B"],
  ["#FFBC42", "#D81159", "#8F2D56", "#218380"],
  ["#E3E36A", "#C16200", "#881600", "#49010F"],
  ["#379392", "#4FB0C6", "#4F86C6", "#6C49B8"],
  ["#67D5B5", "#EE7785", "#C89EC4", "#84B1ED"],
  ["#F16B6F", "#C5C6B6", "#AACD6E", "#3C3530"],
  ["#77AAAD", "#6E7783", "#D8E6E7", "#9DC3C1"],
  ["#AF4034", "#3E4348", "#C65146", "#EC6A5C"],
  ["#F7AA97", "#ED9282", "#DE7E73", "#CFAA9E"],
  ["#D4DFE6", "#8EC0E4", "#CADBE9", "#6AAFE6"],
  ["#D09E88", "#FADAD8", "#534847", "#9B8281"],
  ["#F0E5DE", "#ABD0CE", "#7C7877", "#D9D4CF"],
  ["#AAABD3", "#CBA6C3", "#F8FAFF", "#353866"],
  ["#75D701", "#F8F8FF", "#56A902", "#3B4E32"],
  ["#a5dff9", "#ef5285", "#60c5ba", "#feee7d"],
  ["#d6ecfa", "#f15c5c", "#b84a39", "#6f3826"],
  ["#f9c00c", "#00b9f1", "#7200da", "#f9320c"],
  ["#fab1ce", "#f349eb", "#f100e5", "#71226e"],
  ["#fab1ce", "#ffbbd6", "#f199bc", "#99f19e"],
  ["#f6ea8c", "#f26d5b", "#c03546", "#492540"],
  ["#a3a1a1", "#e3dede", "#8b8687", "#333030"],
  ["#282c37", "#9baec8", "#d9e1e8", "#2b90d9"],
  ["#a3c9c7", "#cb7575", "#ef9e9f", "#353848"],
  ["#a5d296", "#8283a7", "#54546c", "#404146"],
  ["#ff7473", "#ffc952", "#47b8e0", "#34314c"],
  ["#e1eef6", "#ff5f2e", "#fcbe32", "#004e66"],
  ["#f94e3f", "#e77e4d", "#e3632d", "#6f2108"],
  ["#fffff5", "#ffda8e", "#80d4f6", "#5c196b"],
  ["#7f9eb2", "#77919d", "#dae9f4", "#274c5e"],
  ["#ff7761", "#fdc23e", "#f9a11b", "#274555"],
  ["#fff1b9", "#5e5e5f", "#3f4040", "#272625"],
  ["#eb9f9f", "#f1bbba", "#f8ecc9", "#a79c8e"],
  ["#ede574", "#f9d423", "#fc913a", "#ff4e50"],
  ["#00dffc", "#008c9e", "#005f6b", "#343838"],
  ["#e94e77", "#d68189", "#c6a49a", "#c6e5d9"],
  ["#490a3d", "#bd1550", "#e97f02", "#f8ca00"],
  ["#cff09e", "#a8dba8", "#79bd9a", "#3b8686"],
  ["#fe4365", "#fc9d9a", "#f9cdad", "#c8c8a9"],
  ["#bf209f", "#d62a9d", "#ed317f", "#d64072"],
  ["#f4f7f7", "#aacfd0", "#79a8a9", "#1f4e5f"],
  ["#fec9c9", "#ee6e9f", "#6d9d88", "#1a1a1a"],
  ["#fffcf0", "#dedcee", "#6a60a9", "#fbd14b"],
  ["#cff0da", "#88dba3", "#dadbdb", "#3ac569"],
  ["#8c9184", "#3a5134", "#4f953b", "#a7a7a2"],
  ["#6d819c", "#e4e7ec", "#55967e", "#263959"],
  ["#45d9fd", "#f4f4f4", "#ee2560", "#08182b"],
  ["#f1404b", "#dddfe6", "#f4f5f9", "#252c41"],
  ["#a3daff", "#1ec0ff", "#0080ff", "#03a6ff"],
  ["#d3e0f7", "#c72e45", "#a6172d", "#181842"],
  ["#e85a71", "#d8e9ef", "#4ea1d3", "#454552"],
  ["#fec8c9", "#fd999a", "#444f59", "#3e4149"],
  ["#df405a", "#87314e", "#512645", "#311e3e"],
  ["#f0f5f9", "#c9d6de", "#52616a", "#1e2022"]
];
const palleteKey = p => p.join();
const Colors = ({
  backgroundColor,
  onBackgroundColorChanged,
  textColor,
  onTextColorChanged,
  pallete,
  setPallete
}) => {
  return (
    <div>
      <ColorPicker
        label="Cor de Fundo"
        labelColor={textColor}
        colors={bgColors}
        color={backgroundColor}
        onColorChanged={c => {
          onBackgroundColorChanged(c);
          onTextColorChanged(bgColors[[...bgColors].reverse().indexOf(c)]);
        }}
        justify="start"
      />
      <label
        className="font-light mt-6 mb-4 block"
        style={{ color: textColor }}
      >
        Cores da Onda
      </label>
      {palletes.map(p => (
        <div
          key={palleteKey(p)}
          className="flex flex-row items-end cursor-pointer focus:outline-none"
          onClick={() => setPallete(p)}
        >
          {p.map((c, i) => (
            <div
              key={c}
              className={cn("h-10 w-10 rounded-full shadow m-2", {
                "-ml-4": i > 0,
                "h-12 w-12 shadow-xl": palleteKey(p) === palleteKey(pallete)
              })}
              style={{ backgroundColor: c }}
            />
          ))}
          {palleteKey(p) === palleteKey(pallete) && (
            <div className="h-6 w-6 rounded-full shadow m-2 bg-green-400 -ml-4 flex items-center justify-center text-white text-2xl">
              ✓
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Colors;
